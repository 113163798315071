<script lang="ts" setup>
import { useMainMenu } from './useMainMenu'
import { useSubmenuVisibility } from './useSubmenuVisibility'

const { t } = useI18n()
const { isLoggedIn, user } = useUser()
const { menu } = await useMainMenu()
const { close } = useNavigationMobileModal()
const { activeId, subMenusVisible, onClick, closeSubMenus } = useSubmenuVisibility(close)

const account = {
  label: isLoggedIn.value ? user.value?.firstName ?? t('account.label') : t('login'),
  url: '/shop/account',
}

const cta = computed(() => {
  const item = menu.value.find((item) => item.id === activeId.value)
  return item && item.cta
})
</script>
<template>
  <nav class="navigation">
    <div class="header container">
      <ButtonBase v-show="!subMenusVisible" :to="account.url" class="profile label" @click="close">
        <template #before>
          <Icon name="profile" />
        </template>
        {{ account.label }}
      </ButtonBase>
      <button v-show="subMenusVisible" class="back" @click="closeSubMenus"><Icon name="large-arrow" /></button>
      <button class="close" @click="close"><Icon name="large-close" /></button>
    </div>
    <div class="content">
      <div class="container">
        <div class="same-cell">
          <MainMenuMobile :menu="menu" :active-id="activeId" @click="onClick" />
        </div>
        <SubMenusMobile v-show="subMenusVisible" class="same-cell" :menu="menu" :active-id="activeId" />
      </div>
    </div>

    <div v-show="subMenusVisible" class="footer">
      <div class="container">
        <Button v-if="cta && cta.url" secondary icon="offers" class="button-action" :to="cta.url">
          {{ cta.text }}
        </Button>
      </div>
    </div>
  </nav>
</template>
<style lang="scss" scoped>
.navigation {
  position: relative;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: auto 1fr auto;
}
.header {
  height: var(--header-bar-height);
  display: flex;
  align-items: center;

  button {
    --size-button: 50px;
    --size-icon: 20px;
    --shift: calc((var(--size-button) - var(--size-icon)) / 2);

    width: var(--size-button);
    height: var(--size-button);
    svg {
      width: var(--size-icon);
      height: var(--size-icon);
    }
  }
  .profile {
    --pv: 15px;
    :deep(svg) {
      margin-right: 9px;
    }
  }
  .back {
    translate: calc(-1 * var(--shift));
  }
  .close {
    margin-left: auto;
    translate: var(--shift);
  }
}
.content {
  position: relative;
  overflow-y: auto;
  .container {
    display: grid;
  }
}
.same-cell {
  grid-column: 1;
  grid-row: 1;
}

.footer {
  border-top: 1px solid var(--c-line-light);
  padding-block: 18px 24px;
}
.button-action {
  width: 100%;
  max-width: 400px;
}
</style>
